import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Flex,
  Text,
  Link,
  Checkbox,
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  useDimensions,
  Button,
  Highlight,
  Center,
  Spinner
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import TextInput from '../../FormElements/TextInput'
import { OnboardingContainer } from '../../containers/Global'
import { ContainerHeading } from '../../Headings'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { emailResendVerification, LoginValidation } from '../../../formValidation'
import { useAuthContext } from '../../../context/AuthProvider'
import { LoginInputs } from '../../../types'
import SubmitButton from '../../FormElements/SubmitButton'
import ModalBasic, { DefaultModal } from '../../Modals'
import { MdOutlineMarkEmailUnread } from 'react-icons/md'
import { ONBOARDING_URL } from '../../../constants'

export type ResponseType = {
  status: string
  title: string
  description: string
}

const initValues: LoginInputs = {
  email: '',
  password: '',
  rememberMe: false
}

const initResponse = {
  status: '',
  title: '',
  description: ''
}

const LoginContainer: FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const { login, user, isAuthenticated, resendVerificationEmail } = useAuthContext()
  const [isError, setIsError] = useState<boolean>(false)
  const [alertHeight, setAlertHeight] = useState<number>(0)
  const [response, setResponse] = useState<ResponseType>(() => initResponse)
  const alertBoxRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useDimensions(alertBoxRef, true)
  const location = useLocation()

  //Email verification State
  const [userResendEmail, setUserResendEmail] = useState<string>('')
  const [isResendModalOpen, setIsResendModalOpen] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  // Check if user is Authenticated on Page load and any time
  // The user or isAuthenticared state changes
  useEffect(() => {
    if (isAuthenticated && location.pathname.includes('login')) {
      if (user?.info.initialOnboardingStarted) {
        navigate('/user/dashboard', { replace: true })
      } else {
        navigate('/user/dashboard?q=vetting', { replace: true })
      }
    }
  }, [isAuthenticated, user])

  // Get the hight of our Alert Box
  useEffect(() => {
    if (alertBoxRef.current) setAlertHeight(alertBoxRef.current.offsetHeight)
  }, [dimensions?.borderBox.height, dimensions?.borderBox.width, isError])

  const handleCloseError = () => {
    setIsError(false)
  }

  const handleLogin = async ({ email, password, rememberMe }: LoginInputs) => {
    setIsError(false)
    try {
      login && (await login(email.toLowerCase(), password, !!rememberMe))
      setIsError(false)
    } catch (e: unknown) {
      let errorMessage = ''

      if (typeof e === 'string') {
        errorMessage = e.toUpperCase()
      } else if (e instanceof Error) {
        errorMessage = e.message
      }

      setResponse({
        status: 'error',
        title: 'Invalid Credentials',
        description: errorMessage
      })

      setIsError(true) //Set to True so that we can show the Error Modal
    }
  }

  const handleCloseResendVerificationModal = () => {
    setIsResendModalOpen(false)
    setIsSending(false)
    setError(null)
    setSuccess(null)
  }

  return (
    <>
      <OnboardingContainer height={'100%'} maxH={'100%'} overflow="visible" gap="50px">
        <Formik
          initialValues={initValues}
          validateOnMount
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={LoginValidation}
          onSubmit={({ email, password, rememberMe }) =>
            handleLogin({ email, password, rememberMe })
          }
        >
          <Form onChange={handleCloseError}>
            <Flex
              direction={'column'}
              w="full"
              gap={'25px'}
              alignItems="center"
              justifyContent={'center'}
            >
              <Box w="full" maxW={'400px'}>
                <ContainerHeading title="Login" />
              </Box>
              <Box
                w="full"
                maxW={'400px'}
                height={isError ? `${alertHeight}px` : '0'}
                overflow="hidden"
                transition={'all 0.2s ease-in-out'}
              >
                <Alert status="error" gap="25px" rounded="8px" ref={alertBoxRef}>
                  <AlertIcon />
                  <AlertDescription fontSize={'sm'} w="full">
                    {response.description}
                  </AlertDescription>
                </Alert>
              </Box>
              <Flex direction={'column'} w="full" maxW={'400px'} gap={'25px'}>
                <TextInput name="email" label="Email" placeholder="Email address" />
                <TextInput
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                />
                <Flex
                  w="full"
                  justifyContent={'space-between'}
                  alignItems="center"
                  color={'brand.text.primary'}
                  fontSize={'14px'}
                  margin={'10px 0'}
                >
                  <Checkbox color={'brand.text.primary'} fontSize={'14px'}>
                    <Text color={'brand.text.primary'} fontSize={'14px'}>
                      Remember me
                    </Text>
                  </Checkbox>
                  <Link href="send-reset-password-link">Forgot password</Link>
                </Flex>
                <SubmitButton title={'Log in'} />
              </Flex>
              <Flex
                mt="50px"
                direction={'column'}
                gap="25px"
                w="full"
                maxW={'400px'}
                alignItems={'center'}
                justifyItems="center"
              >
                <Text color={'brand.text.primary'} fontSize={'14px'}>
                  Don't have an account?
                  <Link
                    fontWeight={700}
                    pl="5px"
                    onClick={() => {
                      window.open(`${ONBOARDING_URL}/`, '_blank')
                    }}
                  >
                    Go to sign up
                  </Link>
                </Text>
              </Flex>
              <Flex
                direction={'column'}
                gap="25px"
                w="full"
                maxW={'400px'}
                alignItems={'center'}
                justifyItems="center"
              >
                <Text textAlign={'center'} color={'brand.text.primary'} fontSize={'14px'}>
                  Didn't get the verification email?
                  <Button
                    onClick={() => {
                      setIsResendModalOpen(true)
                    }}
                    variant={'unstyled'}
                    fontSize={14}
                    rounded={'full'}
                    borderColor={'brand.secondary'}
                    ml={'5px'}
                  >
                    Resend
                  </Button>
                </Text>
              </Flex>
            </Flex>
          </Form>
        </Formik>
        <DefaultModal
          isOpen={isResendModalOpen}
          onClose={() => {
            setIsResendModalOpen(false)
            handleCloseResendVerificationModal()
          }}
          heading={'Resend verification email'}
        >
          <Flex direction={'column'}>
            <Formik
              initialValues={{ userResendEmail: '' }}
              enableReinitialize
              validateOnMount
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={emailResendVerification}
              onSubmit={async (values, { setSubmitting }) => {
                setIsSending(true)
                setSuccess(null)
                setError(null)
                try {
                  resendVerificationEmail && (await resendVerificationEmail(values.userResendEmail))
                  setSuccess('Please check your email for the verification link')
                  setIsSending(false)
                } catch (e) {
                  console.log('There was an error')
                  let errorMessage = ''
                  setIsSending(false)
                  if (typeof e === 'string') {
                    errorMessage = e.toUpperCase()
                  } else if (e instanceof Error) {
                    errorMessage = e.message

                    setError(errorMessage)
                  }
                }
              }}
            >
              {({ isSubmitting, isValid, dirty, errors, values }) => (
                <Form>
                  {(success || error) && (
                    <Flex
                      direction={'column'}
                      gap={['50px', '25px', '25px', '25px']}
                      alignItems="center"
                      justifyContent={'center'}
                      px="25px"
                    >
                      <Text color={'brand.text.primary'} w="full" textAlign={'center'}>
                        <Highlight
                          query={['check your email', values.userResendEmail]}
                          styles={{ fontWeight: 'bold' }}
                        >
                          {response.description}
                        </Highlight>
                      </Text>
                      <Center
                        color={'brand.secondary'}
                        bgColor={'brand.primary'}
                        rounded="full"
                        // p={4}
                        w="125px"
                        h="125px"
                      >
                        {isSending ? (
                          <Spinner size={'xl'} />
                        ) : (
                          <MdOutlineMarkEmailUnread size={75} />
                        )}
                      </Center>
                      {error && (
                        <Text fontSize={'13px'} color={'brand.text.danger'}>
                          {error}
                        </Text>
                      )}
                      {success && (
                        <Text fontSize={'13px'} color={'brand.text.success'}>
                          {success}
                        </Text>
                      )}

                      <Button
                        type={'button'}
                        variant={'dark'}
                        onClick={() => {
                          handleCloseResendVerificationModal()
                        }}
                      >
                        Got it!
                      </Button>
                    </Flex>
                  )}
                  {!error && !success && (
                    <Flex direction={'column'} gap="25px" textAlign={'center'}>
                      <Text fontSize={'13px'} color={'brand.text.primary'}>
                        Please provide us with your email address.
                      </Text>
                      <Text fontSize={'13px'} color={'brand.text.primary'}>
                        Please note that this verifiaction link will only be valid for 24h
                      </Text>
                      <TextInput name="userResendEmail" label="Email" placeholder="Email address" />
                      <Button
                        type={'submit'}
                        variant={'dark'}
                        isLoading={isSubmitting}
                        disabled={!isValid || isSubmitting || !dirty}
                      >
                        Resend verification email
                      </Button>
                    </Flex>
                  )}
                </Form>
              )}
            </Formik>
          </Flex>
        </DefaultModal>
      </OnboardingContainer>
      {/* <ErrorModal
        isOpen={isError}
        onClose={handleCloseError}
        errorMsg={response.description}
        errorTitle={response.title}
      /> */}
    </>
  )
}

export default LoginContainer
